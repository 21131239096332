import { IntrinsicTask, IntrinsicTaskHintDefaultValue } from '../../../constants/customization';
import { createDevBoxDataPlaneUri } from '../../../ids/dev-box';
import { getTokensFromProjectDataPlaneUri } from '../../../ids/project';
import { AzureDevOpsBranch, AzureDevOpsRepo } from '../../../models/azure-dev-ops';
import { SerializableMap } from '../../../types/serializable-map';
import { SerializableSet } from '../../../types/serializable-set';
import { compact, sortBy, sortByInPlace } from '../../../utilities/array';
import { getAzureDevOpsRepoItemId } from '../../../utilities/azure-dev-ops';
import { compareNumbers } from '../../../utilities/number';
import { getClosestRegion } from '../../../utilities/rd-gateway-url';
import { get, has, set, values } from '../../../utilities/serializable-map';
import { add, has as setHas } from '../../../utilities/serializable-set';
import { areStringsEquivalent, isUndefinedOrEmpty } from '../../../utilities/string';
import {
    ImageViewModel,
    LatencyBand,
    RegionDropdownOption,
    RegionViewModel,
    RegionViewModelMap,
    SizeViewModel,
} from '../models';
import { IntrinsicTaskDescriptionMessages, IntrinsicTaskHintMessages } from './messages';
import { DevBoxNameFieldErrorType, IntrinsicTaskWithDescription } from './models';

// Note: below regex is borrowed from API spec.
// https://devdiv.visualstudio.com/DefaultCollection/OnlineServices/_git/azure-devcenter?path=/src/sdk/specification/devcenter/data-plane/Microsoft.DevCenter/preview/2023-07-01-preview/devbox.json&version=GBmain&line=2317&lineEnd=2317&lineStartColumn=7&lineEndColumn=54&lineStyle=plain&_a=contents
const DevBoxNameFormat = /^[a-zA-Z0-9][a-zA-Z0-9-_\.]*[a-zA-Z0-9-_]$/;
const DevBoxNameMaximumLength = 63;
const DevBoxNameMinimumLength = 3;

export const getDevBoxNameFieldErrorType = (
    value: string,
    projectId: string | undefined,
    user: string,
    existingDevBoxIdentifiers: string[]
): DevBoxNameFieldErrorType => {
    // Note: doing this check separately and first to prevent nullrefs at runtime
    if (isUndefinedOrEmpty(value)) {
        return DevBoxNameFieldErrorType.TooShort;
    }

    if (!DevBoxNameFormat.test(value)) {
        return DevBoxNameFieldErrorType.InvalidFormat;
    }

    if (value.length < DevBoxNameMinimumLength) {
        return DevBoxNameFieldErrorType.TooShort;
    }

    if (value.length > DevBoxNameMaximumLength) {
        return DevBoxNameFieldErrorType.TooLong;
    }

    // Don't check for an existing dev box name unless a project has been selected
    if (projectId) {
        const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(projectId);
        const devBoxId = createDevBoxDataPlaneUri({ devBoxName: value, devCenter, projectName, user });

        // Note: dev boxes are expected to be unique per project and owner. As the Dev Portal is presently focused on showing users
        // only the dev boxes that they own, we assume all dev boxes identified within a project during discovery belong to the
        // signed-in user. However, if we ever have scenarios where we load dev boxes that don't belong to the signed-in user,
        // we should add logic below that filters the returned list to just the names of dev boxes the user is an owner of.
        if (existingDevBoxIdentifiers.some((existing) => areStringsEquivalent(existing, devBoxId))) {
            return DevBoxNameFieldErrorType.AlreadyExists;
        }
    }

    return DevBoxNameFieldErrorType.None;
};

export const isRepoUriValidFormat = (value: string): boolean => {
    let url: URL;
    try {
        url = new URL(value);
    } catch (error) {
        return false;
    }

    return !!url;
};

export const getValueForRepoItem = <TValue>(
    values: SerializableMap<TValue>,
    repo: AzureDevOpsRepo | undefined,
    branch: AzureDevOpsBranch | undefined,
    filePath: string | undefined
): TValue | undefined => {
    if (!repo || !branch || !filePath) {
        return undefined;
    }

    const repoItemPathIdentifier = getAzureDevOpsRepoItemId(repo.url, branch.name, filePath);

    return get(values, repoItemPathIdentifier);
};

export const getDefaultBranch = (
    branches: SerializableMap<AzureDevOpsBranch[]>,
    repo: AzureDevOpsRepo
): AzureDevOpsBranch | undefined => {
    const branchesForRepo = get(branches, repo.url);

    if (!repo?.defaultBranch || !branchesForRepo) {
        return undefined;
    }

    return branchesForRepo.find((branch) => branch.name === repo.defaultBranch);
};

export const getIntrinsicTaskItems = (
    intrinsicTasks: IntrinsicTask[],
    devBoxName?: string
): IntrinsicTaskWithDescription[] => {
    const intrinsicTaskItems: IntrinsicTaskWithDescription[] = [];
    intrinsicTasks.forEach((task) => {
        switch (task) {
            case IntrinsicTask.UpdatePcName:
                const updatePcNameIntrinsicTask: IntrinsicTaskWithDescription = {
                    key: IntrinsicTask.UpdatePcName,
                    description: IntrinsicTaskDescriptionMessages.UpdatePcName,
                    hint: IntrinsicTaskHintMessages.UpdatePcName,
                    // If devBoxName is not provided, use a default value to display in the hint instead of an empty string
                    hintParameters: {
                        devBoxName: isUndefinedOrEmpty(devBoxName)
                            ? IntrinsicTaskHintDefaultValue.DevBoxName
                            : devBoxName,
                    },
                };

                intrinsicTaskItems.push(updatePcNameIntrinsicTask);
                break;
            case IntrinsicTask.EnableTeamsVdiOptimization:
                const enableTeamsVdiOptimizationIntrinsicTask: IntrinsicTaskWithDescription = {
                    key: IntrinsicTask.EnableTeamsVdiOptimization,
                    description: IntrinsicTaskDescriptionMessages.EnableTeamsVdiOptimization,
                    hint: IntrinsicTaskHintMessages.EnableTeamsVdiOptimization,
                };

                intrinsicTaskItems.push(enableTeamsVdiOptimizationIntrinsicTask);
                break;
            default:
                break;
        }
    });

    return intrinsicTaskItems;
};

export const getHasOptimalRegions = (regions: RegionViewModel[]): boolean => {
    const optimalRegions = regions.filter((region) => region.latencyBand !== LatencyBand.Poor);

    return optimalRegions.length > 0;
};

export const getRegionsToRegionRecommendations = (
    images: ImageViewModel[],
    regionRecommendations: RegionViewModelMap
): RegionViewModel[] => {
    const sourceRegion = values(regionRecommendations)[0].sourceRegion;

    const regionToRegionRecommendations = images.map((image) => {
        const regionRecommendation = get(regionRecommendations, image.region);

        if (regionRecommendation) {
            const { id, name, region, latencyBand, roundTripTime } = regionRecommendation;

            // The id is updated here so that it's unique for each dropdown option
            return {
                id: `${id}-${image.id}`,
                sourceRegion,
                poolName: image.poolName,
                name,
                region,
                latencyBand,
                roundTripTime,
            };
        }

        // Special case for if region is same as current region
        if (image.region === sourceRegion) {
            return {
                id: `${image.region}-${image.id}`,
                name: image.regionDisplayName,
                sourceRegion,
                region: image.region,
                latencyBand: LatencyBand.Excellent,
                roundTripTime: 0,
                poolName: image.poolName,
            };
        }

        // If regionRecommendation is undefined, then check if there's a closest region
        const closestRegion = getClosestRegion(image.region);
        const closestRegionRecommendation = get(regionRecommendations, closestRegion);

        return (
            closestRegionRecommendation && {
                id: `${image.region}-${image.id}`,
                name: image.regionDisplayName,
                sourceRegion,
                region: image.region,
                latencyBand: closestRegionRecommendation.latencyBand,
                roundTripTime: closestRegionRecommendation.roundTripTime,
                poolName: image.poolName,
            }
        );
    });

    return sortByInPlace(
        compact(regionToRegionRecommendations),
        (option) => option.roundTripTime,
        (a, b) => compareNumbers(a, b)
    );
};

export const getTopRecommendedRegions = (
    options: RegionDropdownOption[],
    value: RegionDropdownOption | undefined
): RegionDropdownOption[] => {
    //Top 5 recommended
    for (let i = 5; i < options.length; i++) {
        if (!!options[i]) {
            options[i].hidden = true;
            // When a user selects an option via the See all dialog,
            // if it is not in the top 5 modified, needs to be shown or else dropdown will have a blank value
            if (!!options[i].id && !!value?.id && options[i].id === value.id) {
                options[i].hidden = false;
            }
        }
    }
    return options;
};

export const hideNonOptimalRegions = (options: RegionDropdownOption[]): void => {
    options.forEach((option) => {
        if (option.latencyBand === LatencyBand.Poor) {
            option.hidden = true;
        }
    });
};

export const removeSizeDuplicates = (sizes: SizeViewModel[]): SizeViewModel[] => {
    let seen: SerializableMap<boolean> = {};

    return sizes.filter((size) => {
        const keyValue = `${size.cpuCount}-${size.diskSizeInGb}-${size.memoryInGb}`;

        if (!has(seen, keyValue)) {
            seen = set(seen, keyValue, true);
            return true;
        }

        return false;
    });
};

export const getSizeOptions = (sizes: SizeViewModel[]): SizeViewModel[] => {
    const sizeIsDuplicateMap = new Map<string, boolean>();

    sizes.forEach((size) => {
        const keyValue = `${size.cpuCount}-${size.diskSizeInGb}-${size.memoryInGb}`;

        if (sizeIsDuplicateMap.has(keyValue)) {
            sizeIsDuplicateMap.set(keyValue, true);
        } else {
            sizeIsDuplicateMap.set(keyValue, false);
        }
    });

    const sizeOptions: SizeViewModel[] = sizes.map((size) => {
        return {
            ...size,
            isDuplicateSize: sizeIsDuplicateMap.get(`${size.cpuCount}-${size.diskSizeInGb}-${size.memoryInGb}`),
        };
    });

    return sizeOptions;
};

export const removeRegionDuplicates = (regions: RegionViewModel[]): RegionViewModel[] => {
    let seen: SerializableSet<string> = [];

    return regions.filter((region) => {
        const { name } = region;
        if (!setHas(seen, name)) {
            seen = add(seen, name);
            return true;
        }

        return false;
    });
};

export const getRegionOptions = (
    regions: RegionViewModel[],
    hasOptimalRegions: boolean,
    hasMultipleRegions: boolean,
    selectedRegion: RegionDropdownOption | undefined
): RegionDropdownOption[] => {
    const regionsCopy: RegionDropdownOption[] = [...regions];

    // get top 5 recommended regions
    const recommendedRegions = getTopRecommendedRegions(regionsCopy, selectedRegion);

    // hide all the regions that have poor latency
    hideNonOptimalRegions(recommendedRegions);

    // if there are no optimal regions, we add a `no-optimal-regions` option to the dropdown
    // otherwise we just add the `show all`
    if (!hasOptimalRegions) {
        recommendedRegions.push({
            id: 'no-optimal-regions',
            name: 'No optimal regions',
            sourceRegion: 'Global',
            region: 'Global',
            disabled: true,
            latencyBand: LatencyBand.Good,
            roundTripTime: 100,
        });
        recommendedRegions.push({
            id: 'show-all',
            name: 'Show all',
            region: 'Global',
            sourceRegion: 'Global',
            latencyBand: LatencyBand.Poor,
            roundTripTime: 150,
        });
    } else if (hasMultipleRegions) {
        recommendedRegions.push({
            id: 'show-all',
            name: 'Show all',
            region: 'Global',
            sourceRegion: 'Global',
            latencyBand: LatencyBand.Poor,
            roundTripTime: 150,
        });
    }

    return recommendedRegions;
};

export const getLastUsedImages = (
    images: ImageViewModel[],
    recentlyUsedImages: SerializableMap<Date>
): ImageViewModel[] => {
    const lastUsedImages = images.map((image) => {
        return { ...image, recentlyUsed: has(recentlyUsedImages, image.imageName) };
    });

    return sortBy(lastUsedImages, (image) => image.recentlyUsed, undefined, true);
};
